import { createRoot } from 'react-dom/client';

import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/font-awesome/css/font-awesome.min.css';
import 'assets/scss/argon-design-system-react.scss';

import Index from 'views/Index.jsx';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Index />);
